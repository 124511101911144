import { Box, Text, Link as ChakraLink, Flex } from "@chakra-ui/react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import SideHeader from "../atom/side-header"

const Profile = ({ headerDisplay = { base: "block", lg: "none" } }) => {
  return (
    <Box maxW="800px" mx="auto">
      <Box display={headerDisplay}>
        <SideHeader>プロフィール</SideHeader>
      </Box>
      <Box borderColor="#5c5744" p={2} bg="rgba(256,256,256,0.8)">
        <Flex justify="center" align="center" my={2}>
          <Box>
            <StaticImage
              className="bio-avatar"
              layout="constrained"
              formats={["auto", "webp", "avif"]}
              src="../../images/profile-pic.png"
              width={50}
              height={50}
              quality={95}
              alt="プロフィールイメージ"
            />
          </Box>
          <Link to="/about#profile">
            <Text fontSize="md" _hover={{ textDecoration: "underline" }}>
              はち子
            </Text>
          </Link>
        </Flex>
        <ProfileSentence />

        {/* <ChakraLink href="https://twitter.com/bun_sugi?ref_src=twsrc%5Etfw">
        <TwitterIcon size={20} round iconFillColor="white" />
      </ChakraLink> */}
      </Box>
    </Box>
  )
}

export default Profile

const ProfileSentence = () => (
  <Box my={4} fontSize="sm">
    <Text mb={2}>
      事業会社のシステム部門で働く会社員。→転職してビジネス部門でシステム関連の業務を行っています。プロダクトマネージャー／システム企画／要件定義／システムアーキテクチャ等。
    </Text>
    <Text fontSize="md">
      Twitter→
      <ChakraLink
        href="https://twitter.com/bun_sugi?ref_src=twsrc%5Etfw"
        color="blue.500"
      >
        @bun_sugi
      </ChakraLink>
    </Text>
  </Box>
)
